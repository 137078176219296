<template>
    <header>
        <img src="../assets/top.svg" alt="" style="width: 100%;">
        <div class="containerTip">
            <router-link to="./home" class="ins insLoge"></router-link>
            <router-link to="./home" class="ins">home</router-link>
        </div>
    </header>

    <section>
        <div class="container" style="margin-bottom: 40px;">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <h2 class="h2ss">Terms of Use</h2>
                </div>
            </div>
            <div>
                <p class="mt-10 isContent">
                    Last updated:March 4, 2024
                </p>
                <p class="mt-10 isContent">
                    By accessing or using our products or services, you agree to be bound by these Terms and all policies referenced herein. Please read these Terms carefully before downloading or using our services. If you do not understand the Terms, or do not accept any part of them, please do not use our services.
                </p>
                <h3 class="mt-20 mb-10 titles">
                    User-Generated Content 
                </h3>
                <p class="mt-10 isContent">
                    You are solely responsible for your use of our services and for any videos, information, messages and any other content that you create via our services, whether privately transmitted or made publicly available (together, “User Content”). You retain ownership of your User Content.
                </p>
                <p class="mt-10 isContent">
                    Any videos you make by using our services are stored locally in your phone and are not stored in or will not be uploaded to our services/server.
                </p>
                <p class="mt-10 isContent">
                    You understand and agree to comply with all applicable laws in relation to your use of our services and User Content.
                </p>
                <h3 class="mt-20 mb-10 titles">
                    You shall at all times ensure that your User Content does not:
                </h3>
                <p class="mt-10 isContent">
                    A. contain any content, information or material that infringes the rights of any third party including copyright, trademark right, confidential information or rights of privacy;
                </p>
                <p class="mt-10 isContent">
                    B. violate any applicable laws;
                </p>
                <p class="mt-10 isContent">
                    C. contain any content or material that is offensive, abusive, defamatory, libellous, derogatory, bullying, discriminatory, obscene, violent, sexually explicit, indecent, which promotes or encourages violence, terrorism or any other illegal acts or which is likely to harass, upset, embarrass, alarm, inconvenience or annoy any person;
                </p>
                <p class="mt-10 isContent">
                    D. in any way promote or incite anyone to commit or assist in any unlawful or criminal activity or anti-social behaviour, or encourage activities which could endanger the safety or wellbeing of others;
                </p>
                <p class="mt-10 isContent">
                    E. identify any person without their consent, or the consent of their parent or legal guardian if they are under 18 years of age;
                </p>
                <p class="mt-10 isContent">
                    F. disclose anyone’s personal contact details or invade their privacy;
                </p>
                <p class="mt-10 isContent">
                    G.contain any viruses or other malicious or harmful programs;
                </p>
                <h3 class="mt-20 mb-10 titles">Furthermore, you understand and agree that you will not do or attempt to do, or cause any third party to do or attempt to do, any of the following in connection with your use of our services:

                </h3>
                <p class="mt-10 isContent">
                    A. Use our services or User Content available on our services in a way other than as expressly permitted by these Terms and the normal functionality of our services;
                </p>
                <p class="mt-10 isContent">B. use our services for any illegal purpose, in an unlawful manner or in any manner inconsistent with these Terms;
                </p>
                <p class="mt-10 isContent">C. use our services in a way that could damage, disable, overburden, impair or compromise our services, our systems or security or interfere with other users;
                </p>
                <p class="mt-10 isContent">
                    D. use any programme or other means, including but not limited to scripts, spiders and robots, whether manually or automatically, to extract, download, index, mine, scrape, reproduce or circumvent the presentation, operation or intended use of our services or any features or functions of it;
                </p>
                <p class="mt-10 isContent">
                    E. copy, modify, decompile or otherwise interfere with any part of our services;
                </p>
                <p class="mt-10 isContent">
                    F. make alterations to, or modifications of, the whole or part of our services, or permit our services or any part of it to be combined with, or become incorporated in, any other programs;
                </p>
                <p class="mt-10 isContent">
                    G. hack into, or insert malicious code, including viruses, or harmful data, into, our services;
                </p>
                <p class="mt-10 isContent">
                    H.infringe our intellectual property.
                </p>
                <h3 class="mt-20 mb-10 titles">Copyright </h3>
                <p class="mt-10 isContent">Face AI respects the intellectual property of others, and we ask our users to do the same. Face AI will promptly process and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium Copyright Act ("DMCA") and other applicable intellectual property laws with respect to any alleged or actual infringement.
                </p>

                <h3 class="mt-20 mb-10 titles">If you find any content on our services that you believe infringes upon your copyright, or discover any other infringement of your intellectual property rights, please contact us for removal by email at: floralong20231020@gmail.com
               </h3>
               <h3 class="mt-20 mb-10 titles">Third Party Services 
               </h3>
                <p class="mt-10 isContent">Our service includes and links to features and services (including but not limited to, social applications like Facebook, Instagram) that are provided by a third party. We do not control such third party sites or services and are not responsible for the content or functionality of such sites or services. Our inclusion of links does not imply any endorsement or association with their operators. The terms applicable to the use of such third party services will apply and we will not be responsible for anything that is done by you or the third party service provider in connection with your use of their service. Please note that we do not keep your private personal information like your account, ID, password and etc. on the third party apps/websites in our services.Advertising 
                </p>
                <p class="mt-10 isContent">
                    We may deliver advertising to you in conjunction with your use of our services.Privacy Policy 
                </p>
                <p class="mt-10 isContent">
                    Your privacy is very important to Face AI. Please refer to our Privacy policy for information on how we collect, use and disclose personal information.
                </p>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    // 组件逻辑
}
</script>

<style scoped>
header {
    position: relative;

    .containerTip {
        position: absolute;
        width: 100%;
        top: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 7%;

        .ins {
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: PingFang SC;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-decoration: auto;
        }

        .insLoge {
            font-size: 48px;
        }
    }
}

section {
    .isColor {
        color: #000;
        font-weight: 600;
    }

    .isLink {
        color: #2a73f7;
    }

    .col-lg-6 {
        margin: 60px 0 80px 0;

        .h2ss {
            font-size: 64px;
            color: #333;
            font-weight: 500;
            text-align: center;
        }
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    p {
        text-indent: 25px;
    }

    .titles {
        color: #333;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px;
    }

    .isContent {
        color: #404040;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
}
</style>