<template>
    <header>
        <img src="../assets/top.svg" alt="" style="width: 100%;">
        <div class="containerTip">
            <router-link to="./home" class="ins insLoge"></router-link>
            <router-link to="./home" class="ins">home</router-link>
        </div>
    </header>

    <section>
        <div class="container" style="margin-bottom: 40px;">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <h2 class="h2ss">PRIVACY POLICY</h2>
                </div>
            </div>
            <div>
                <p class="mt-10 isContent">
                    Last updated:March 4, 2024
                </p>
                <p class="mt-10 isContent">
                    Face AI ("we", "us", "our") recognizes the importance of maintaining your privacy. We are committed to protecting and respecting your privacy. Face AI Privacy Policy (“Privacy Policy”, “Policy”) describes how we handle user information collected through our application (“App”, “Application”, or "Platform"). By downloading, using and/or accessing our Services through the Platform, you expressly agree to the terms described in this Policy. If you do not agree with any of the terms in this policy, please do not use our services.
                    This Policy only applies to mobile application that reference or link to this Privacy Policy. 
                </p>
                <h3 class="mt-20 mb-10 titles">👉 1.Face Data Policy
                </h3>
                <h3 class="mt-20 mb-10 titles">What face data does our app collect?
                </h3>
                <p class="mt-10 isContent">
                    We analyze your photos to find the feature points of the faces in the photos (eg eyes, nose, mouth points) for face processing. We do not collect facial data. After the analysis process, all data will be deleted and no data will be saved.
                </p>
                <h3 class="mt-20 mb-10 titles">How do you use the collected face data? (Purpose and process of collecting face data)

                </h3>
                <p class="mt-10 isContent">
                    In order to give you the "face swap" effect, we need to find feature points (e.g. eyes, nose, mouth points). 

                </p>

                <h3 class="mt-20 mb-10 titles">The complete process is : </h3>
                <p class="mt-10 isContent">(1)You submit the selected photo to our server in an encrypted way;</p>
                <p class="mt-10 isContent">(2)The server analyzes the face data in your photo and applies it to the template you choose;
                </p>
                <p class="mt-10 isContent">(3)After the composition is completed, the server returns the final rendering effect to you through the application;
                </p>
                <p class="mt-10 isContent">(4)The final composite effect will be temporarily stored in encrypted storage, and they will be permanently deleted after seven days. After the compositing process is complete, we will delete the face image you submitted to the server immediately.
                </p>
                   
                <h3 class="mt-20 mb-10 titles">• Will the data be shared with any third parties? Where will this information be stored?
                </h3>
                <p class="mt-10 isContent">
                    We will not share your face data with any third party, nor will we store any face information you submit, and the photos you upload will be deleted after the analysis process is complete.
                </p>
                <h3 class="mt-20 mb-10 titles">• How long will face data be retained? 
                </h3>
                <p class="mt-10 isContent">
                    The final composite effect will be temporarily stored in encrypted storage, and they will be permanently deleted after seven days. After the compositing process is complete, we will delete the face image you submitted to the server immediately.
                </p>
                <h3 class="mt-20 mb-10 titles">👉2.What information we collect
                </h3>
                <p class="mt-10 isContent">
                    (1) We may receive the information described in this Policy from other sources, such as:
                </p>
                <p class="mt-10 isContent">• Social media and login services. If you choose to use a third-party social network or login service to link or register, we may collect information from those services, including your ID, your contact list for those services and the platforms you use in connection with those services. The third-party social networks include Apple, Google and Meta.
                </p>
                <p class="mt-10 isContent">• Third Party Services. We may collect information about you from third-party services, such as advertising partners, data providers and analytics providers.
                </p>
                <p class="mt-10 isContent">• Other sources. We may collect information about you from other publicly available sources.
                </p>
                <p class="mt-10 isContent">(2) We collect information directly from you.
                </p>
                <p class="mt-10 isContent">We collect information directly from you when you register for an account or use the Services.
                </p>
                <p class="mt-10 isContent">• Image information. When you use the functionality of generating images, we will collect the images you upload to generate or process images.
                </p>
                <p class="mt-10 isContent">• Contact information. When you log in with the account of a third-party media website, we will collect your account information on the third-party media website, generally including: account name, email address and region.
                </p>
                <p class="mt-10 isContent">• Feedback. When you contact us for feedback or customer service, we collect the information and materials you submit, your contact details (such as your email) so that we can contact you and answer your questions or provide other services.
                </p>
                <p class="mt-10 isContent">(3) We collect information automatically.
                </p>
                <p class="mt-10 isContent">• Usage information. We may collect your operation information on the platform, mainly including the information you click, browse, download, share and your search history, or any other information you provide during the use of our services, so that we can provide you with features.
                </p>
                <p class="mt-10 isContent">• Device and log information. When you use our services, in order to ensure your normal use of our services and the safe operation of their functions, improve and optimize your use experience, and protect your account security, we will collect your device and log information as follows: device model, mobile phone Set language, mobile phone country, advertising ID, operating system and system version number, login IP address, software version number, access method, type and status to the network, network quality data, operation logs, service log information.
                </p>
                <p class="mt-10 isContent">If you stop consenting or expressly withdraw your consent to our collection, use or disclosure of your information, we will immediately cease all such uses.
                </p>
                <h3 class="mt-20 mb-10 titles">👉3.What permissions do we may ask you to authorize?
                </h3>
                <p class="mt-10 isContent">
                    In order to provide you with better and more efficient products and services and improve your service experience, we may request you to authorize the corresponding terminal device permissions. With your consent, we collect the following permissions and corresponding information in accordance with the minimum data collection standards:
                </p>
                <p class="mt-10 isContent"> Android permission application:
                </p>
                <p class="mt-10 isContent">• Camera permissions: for shooting functions;
                </p>
                <p class="mt-10 isContent">• Read and write external memory cards: for reading and writing files on external memory cards for video editing;
                </p>
                <p class="mt-10 isContent">• Receive notification messages: used to receive messages;
                </p>
                <p class="mt-10 isContent">• Get the current network status: remind the user whether the mobile network is currently in use or determine whether the user's network is available;
                </p>
                <p class="mt-10 isContent">• Advertising ID: collects the device advertising identifier GAID for personalizing advertising content;
                </p>
                <p class="mt-10 isContent">You can check the status of the above permissions in the system settings of the terminal device, and you can turn on or off any or all of the above permissions at any time at your own discretion. Please note that when you turn on any permission，you authorize us to collect and use the personal information corresponding to the permission in order to provide you with the above corresponding services. Once you close any permission, it means that you cancel or revoke the authorization of the permission, we will no longer continue to collect and use the corresponding personal information based on this permission, and we will not be able to provide you with services corresponding to this permission. However, your decision to turn off permissions will not affect the collection and use of information in accordance with your previous authorization.
                </p>
                <p class="mt-10 isContent">• Face Recognition: For certain theme template editing and short video production services, our application collects avatar photos you upload or take. To use some features of the theme templates that require face cutout, you can voluntarily allow our app to access the camera or photo album to get photos from your mobile device. Our app can access your camera and photo album only after you confirm your permission. When you use certain face recognition functions, we will automatically capture the position and shape of your five senses, analyze the facial feature information, store this information on our server for photo processing, and return the renderings to you. We do not store or share such processed photos, facial features or renderings with any third parties.
                </p>
                <h3 class="mt-20 mb-10 titles">👉4.How we use your information and legal basis
                </h3>
                <p class="mt-10 isContent">We may only use your information when we have justifications (each called a "legal basis") under data protection law. To process your information as described in this Policy, we rely on the following legal bases involve contractual necessity, legitimate interests, consent, compliance with applicable laws and regulations.
                </p>
                <p class="mt-10 isContent">(a)Contractual necessity
                </p>
                <p class="mt-10 isContent">Ÿ To meet requests for information on products, services, platform capabilities, support and internal operations, and to solicit your feedback;
                </p>
                <p class="mt-10 isContent">Ÿ To handle and respond to your inquiries and to help you provide timely feedback;
                </p>
                <p class="mt-10 isContent">(b) Legitimate interests
                </p>
                <p class="mt-10 isContent">To pursue our legitimate interests(or those of a third party)as we deem fit and necessary, where your interests and fundamental rights do not override those legitimate interests of ours (or any third party), such as:
                </p>
                <p class="mt-10 isContent">· Improve and develop our platform and conduct product development;
                </p>
                <p class="mt-10 isContent">· Use of information for security purposes: we may use information for account and network security purposes, including to protect our Services for the benefit of all users, and to pre-screen or scan uploaded content for potentially illegal content. We may use your information for identity verification, monitoring account and transaction risk, detection and security prevention, fraud monitoring, archive backup, customer security services, and more;
                </p>
                <p class="mt-10 isContent">· To enforce our terms, conditions and policies and to communicate with you in a timely manner, including notifying you of changes to our services: such as alerts about your account and changes to our terms, conditions and policies;
                </p>
                <p class="mt-10 isContent">· To protect our legal rights and to protect our users and third parties from harm;
                </p>
                <p class="mt-10 isContent">· To respond to complaints, hearings, arbitrations, lawsuits or other governmental or regulatory proceedings.
                </p>
                <p class="mt-10 isContent">(c) Compliance with applicable laws and regulations
                </p>
                <p class="mt-10 isContent">· To comply with legal requirements;
                </p>
                <p class="mt-10 isContent">· Assist law enforcement.
                </p>
                <p class="mt-10 isContent">We store and maintain your information in order to comply with our legal obligations: for example, in an emergency, we may record, review, analyze and dispose of your information in accordance with applicable law.
                </p>
                <p class="mt-10 isContent">(d) Consent
                </p>
                <p class="mt-10 isContent">· Use User Content (as defined in Terms of Use) as part of our advertising and marketing campaigns to promote the Platform: we may send you information about special promotions or offers and new features or products;For any other purpose disclosed to you when we collect your information or with your consent.
                </p>
                <h3 class="mt-20 mb-10 titles">👉5.Cookies and Similar Technologies
                </h3>
                <p class="mt-10 isContent">When you use our services, certain information may be stored locally on your mobile device using "cookies" or similar technologies (such as Google Analytics and web beacons). We use cookies and similar technologies mainly to achieve the following functions or services
                </p>
                <p class="mt-10 isContent">
                    • Ensure the safe and efficient operation of products and services
                </p>
                <p class="mt-10 isContent">We may set cookies or anonymous identifiers for authentication and security, so that we can confirm whether you are safe to log in to the service, or whether you have encountered theft, fraud or illegal activities. These technologies also help us improve the efficiency of our services and improve login and responsiveness.
                </p>
                <p class="mt-10 isContent">• Helps you have an easier access experience
                </p>
                <p class="mt-10 isContent">Using such technologies can help you avoid repeating the steps and processes of filling out your personal information and logging in frequently.
                </p>
                <h3 class="mt-20 mb-10 titles">👉6.How we store your information
                </h3>
                <p class="mt-10 isContent">The length of time we retain personal information is to provide our services or to fulfill the original or directly related purpose for which such information was collected, or for other business purposes, such as complying with our legal obligations, resolving disputes, and enforcing our agreements. We are required by law to retain certain types of information for a certain period of time (such as a statute of limitations). If your personal information is no longer necessary for the legal or business purpose for which it was processed, we will generally destroy or anonymize that information.
                </p>
                <h3 class="mt-20 mb-10 titles">👉7.How we share your information
                </h3>
                <p class="mt-10 isContent">(1) We may share your necessary personal information with third-party service providers to help us deliver and improve products, services, marketing, push/delivery notification bar messages, and to help you share content to third-party media Websites, by logging into third-party accounts and other legitimate business functions. We will confirm on what basis each third party will use your personal information and we will ensure that adequate safeguards and processes are in place to protect your personal data.
                </p>
                <p class="mt-10 isContent">(2) We will disclose or transfer your information only if required to do so by law, or we have obtained your consent, or in good faith that any such action is necessary to: (a) comply with any legal requirement or comply with us or platform services; (b) protect and defend our rights or property; (c) take action in an emergency to protect the personal safety of our users or the public.
                </p>
                <p class="mt-10 isContent">(3) In order to ensure the realization of relevant functions and the safe and stable operation of applications, we may access software development kits (SDK) provided by third parties to achieve relevant purposes. We will conduct strict security monitoring on the software tool development kits (SDK) used by partners to obtain information to protect data security. The relevant third-party SDK we access are listed in this privacy policy.
                </p>
                <p class="mt-10 isContent">Please note that the third-party SDK may change their data processing types due to their version upgrades, policy adjustments, etc., please refer to the official instructions published by them.
                </p>
                <p class="mt-10 isContent">Please noted that if you are a user downloads and use our Application implemented with any analytical or advertising SDK or otherwise interacts with the Application, the advertising company itself may be an independent controller of the personal information processed in order to provide the services that it will individually determine the purposes and means of processing such data. Such data refers to any information or data that advertising company collects through the SDK implemented in our application, in connection with any advertisement or otherwise in connection with the Service, e.g. IP address, device manufacturer, device brand, device model, device name, device operating system information, device configuration information, device environment information, network operator information, network type, network status, device identification information (IDFA/IDFV/UDID/Android ID/). The collection and transmission will not involve personal information. If personal information is involved in the transmission, please be aware that in no event will the advertising party and we process personal information as joint controllers and any information processed by the third parties through third-party SDKs is subject to their respective personal information processing policies.

                </p>
                <p class="mt-10 isContent">Third-party SDK name: FirebaseAnalytics, FirebaseCrashlytics, FirebaseDynamicLinks
                </p>
                <p class="mt-10 isContent">Scope of personal information collection: device information (IDFA/IDFV/UDID/IP)
                </p>
                <p class="mt-10 isContent">Third-party agency name: Google LLC
                </p>
                <p class="mt-10 isContent">Privacy Policy Link: https://firebase.google.com/support/privacy
                </p>
                <p class="mt-10 isContent">--------------------------------------------</p>
                
                <p class="mt-10 isContent">Third-party SDK name: FBSDKCoreKit, FBSDKLoginKit, FBSDKShareKit
                </p>
                
                <p class="mt-10 isContent">Scope of personal information collection: device identification information
                </p>
                
                <p class="mt-10 isContent">Third-party agency name: Meta LLC
                </p>
                <p class="mt-10 isContent">Privacy Policy Link: https://developers.facebook.com/terms/
                </p>
                <p class="mt-10 isContent">--------------------------------------------</p>
                
                <p class="mt-10 isContent">Third-party SDK name: Google-Mobile-Ads-SDK
                </p>
                
                <p class="mt-10 isContent">Purpose of collecting personal information: To provide personalized advertising
                </p>
                <p class="mt-10 isContent">Collection of personal information: Embedding third-party SDK, SDK collects and transmits personal information
                </p>
                <p class="mt-10 isContent">Scope of personal information collection: device identification information
                </p>
                <p class="mt-10 isContent">Third-party agency name: Google LLC
                </p>
                <p class="mt-10 isContent">Privacy Policy Link: https://www.google.com/adsense/new/localized-terms
                </p>
                <p class="mt-10 isContent">--------------------------------------------</p>
                <p class="mt-10 isContent">Purpose of collecting personal information: to provide personalized configuration
                </p>
                <p class="mt-10 isContent">Collection of personal information: Embedding third-party SDKs, SDKs collect and transmit personal information
                </p>
                <p class="mt-10 isContent">Scope of personal information collection: device identification information
                </p>
                <p class="mt-10 isContent">Third-party agency name: Google
                </p>
                <p class="mt-10 isContent">Privacy Policy Link: https://firebase.google.com/support/privacy
                </p>
                <p class="mt-10 isContent">--------------------------------------------</p>
                <p class="mt-10 isContent">Third-party SDK name: Google-Mobile-Ads-SDK
                </p>
                <p class="mt-10 isContent">Purpose of collecting personal information: To provide personalized advertising
                </p>
                <p class="mt-10 isContent">Collection of personal information: Embedding third-party SDK, SDK collects and transmits personal information

                </p>
                <p class="mt-10 isContent">Scope of personal information collection: device identification information
                </p>
                <p class="mt-10 isContent">Third-party agency name: Google LLC
                </p>
                <p class="mt-10 isContent">Privacy Policy Link: https://www.google.com/adsense/new/localized-terms
                </p>
                <p class="mt-10 isContent">--------------------------------------------</p>
                <p class="mt-10 isContent">
                    Third-party SDK name: FirebaseRemoteConfig
                </p>
                <p class="mt-10 isContent">
                    Purpose of collecting personal information: to provide personalized configuration
                </p>
                <p class="mt-10 isContent">
                    Collection of personal information: Embedding third-party SDKs, SDKs collect and transmit personal information
                </p>
                <p class="mt-10 isContent">
                    Scope of personal information collection: device identification information
                </p>
                <p class="mt-10 isContent">
                    Third-party agency name: Google
                </p>
                <p class="mt-10 isContent">
                    Privacy Policy Link: https://firebase.google.com/support/privacy
                </p>
                <p class="mt-10 isContent">--------------------------------------------</p>
                <p class="mt-10 isContent">
                    Third-party SDK name:  AppLovin SDK
                </p>
                <p class="mt-10 isContent">
                    Purpose of collecting personal information: to provide personalized advertising
                </p>
                <p class="mt-10 isContent">
                    Collection of personal information: Embedding third-party SDKs, SDKs collect and transmit personal information
                </p>
                <p class="mt-10 isContent">
                    Scope of personal information collection: device identification information
                </p>
                <p class="mt-10 isContent">
                    Third-party agency name: AppLovin Corporation
                </p>
                <p class="mt-10 isContent">
                    Privacy Policy Link：https://www.mintegral.com/en/privacy/
                </p>
                <p class="mt-10 isContent">--------------------------------------------</p>
                <p class="mt-10 isContent">
                    Other
                </p>
                <p class="mt-10 isContent">
                    Facebook Ads Privacy Policy:https://www.facebook.com/about/privacy/
                </p>
                <p class="mt-10 isContent">
                    Google Ads Privacy Policy:https://policies.google.com/privacy
                </p>
                <p class="mt-10 isContent">
                    Goole Play Refund policies:https://support.google.com/googleplay/answer/2479637#policy
                </p>
                <p class="mt-10 isContent">--------------------------------------------</p>

                <h3 class="mt-20 mb-10 titles">👉8.How we protect your information
                </h3>
                <p class="mt-10 isContent">We are committed to protecting the security of your personal information. To prevent unauthorized access, disclosure or other similar risks, we have put in place reasonable physical, electronic and managerial procedures to protect and secure the information we collect from your use of our services on the platform. We will use all reasonable efforts to protect your personal information.
                </p>
                <h3 class="mt-20 mb-10 titles">9.Your rights</h3>
                <p class="mt-10 isContent">As the subject of the personal information, you are entitled to have the rights provided by the privacy laws in your jurisdiction. Your rights may include part or all of those described underneath. You can exercise your rights entitled by the privacy laws in your jurisdiction at any time by sending us relevant requests to  floralong20231020@gmail.com. Your rights may include:
                </p>
                <p class="mt-10 isContent">(1) Right to be informed:</p>
                <p class="mt-10 isContent">We publish this Policy to inform you of how we handle your personal information. We are committed to the transparency of the use of your information.
                </p>
                <p class="mt-10 isContent">（2）Right to access your personal information:
                </p>
                <p class="mt-10 isContent">You have the right to access personal information we hold about you, how we use it, and who we share it with.
                </p>
                <p class="mt-10 isContent">（3） Right to correct your personal information:
                </p>
                <p class="mt-10 isContent">You have the right to correct your information where that information is not accurate. You can correct your personal information by contacting us via the email stated in this Policy.
                </p>
                <p class="mt-10 isContent">（4）Right to delete your personal information:</p>
                <p class="mt-10 isContent">You can delete your account or remove certain personal information that we have stored about you. However, please note that we may need to retain personal information if there are valid grounds under data protection laws for us to do so (e.g., for the defense of legal claims or freedom of expression) but we will respond to you and let you know if that is the case.
                </p>
                <p class="mt-10 isContent">（5）Right to restrict processing:
                </p>
                <p class="mt-10 isContent">In limited circumstances, you have the right to request us to stop processing your personal information we hold about you other than for storage purposes in certain circumstances.
                </p>
                <p class="mt-10 isContent">（6）Right to data portability:
                </p>
                <p class="mt-10 isContent">You can request a copy of certain data in a machine-readable form that can be transferred to another provider if such right is requested by the privacy laws in your jurisdiction.
                </p>
                <p class="mt-10 isContent">（7）Right of Refusal:
                </p>
                <p class="mt-10 isContent">In certain circumstances (including where data is processed on the basis of legitimate interests or for the purposes of marketing) you may object to that processing.
                </p>
                <p class="mt-10 isContent">If you are located in the European Economic Area (“EEA”), United Kingdom or Switerland, you may also object to the processing of your personal information for certain purposes:
                </p>
                <p class="mt-10 isContent">● For other purposes: you may object to the processing of your personal information where such processing is based on a legitimate interest as described above. Please describe the reasons relating to your particular situation to justify your request. If applicable, we will stop the processing unless we have compelling legitimate grounds.
                </p>
                <h3 class="mt-20 mb-10 titles">👉10.Privacy of Minors/Children
                </h3>
                <p class="mt-10 isContent">We protect the personal information of minors in accordance with the provisions of laws and regulations, and will only collect, use, share or disclose personal information of minors when permitted by law, with the explicit consent of parents or other guardians, or when necessary to protect minors; If we become aware that personal information from minors has been collected without prior verifiable parental consent, we will try to delete the information as soon as possible.
                </p>
                <p class="mt-10 isContent">If you are the guardian of a minor, when you have any questions about the personal information of the minor under your guardianship, please contact us through the contact information published in this Privacy Policy.
                </p>
                <h3 class="mt-20 mb-10 titles">👉11.Changes and Adjustments
                </h3>
                <p class="mt-10 isContent">The effective date of this policy is set out at the top of this policy. We may update this Privacy Policy from time to time and when we do, we will notify you by updating the "Last Updated" date at the top of this Policy and by posting the new Privacy Policy and providing any other notices required by applicable law. Your continued use of the products and services on the Platform will be deemed acceptance of the updated Privacy Policy. We recommend that you review the Privacy Policy each time you visit the Platform to stay informed about our privacy practices.
                </p>
                <h3 class="mt-20 mb-10 titles">👉12.How to contact us
                </h3>
                <p class="mt-10 isContent">If you have any questions/complaints/suggestions/requests about this policy, you can contact our platform customer service or give feedback by clicking "Contact Us" in  floralong20231020@gmail.com.
                </p>
                <p class="mt-10 isContent">Your Rights
                </p>
                <p class="mt-10 isContent">As we described in section 9 “Your rights”, you may access, correct, or delete the personal information at any time. You may also withdraw consent to the collection, use, provision or storage of personal information that you provided. Please be free to contact us at open@pengzhongtech.com if you want to exercise any rights.
                </p>
                <p class="mt-10 isContent">Contact us</p>
                <span class="mt-10 isContent">If you have any questions regarding privacy while using the application, or have questions about our practices, please contact us via email at :
 floralong20231020@gmail.com
                </span>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    // 组件逻辑
}
</script>

<style scoped>
header {
    position: relative;

    .containerTip {
        position: absolute;
        width: 100%;
        top: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 7%;

        .ins {
            color: #FFF;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: PingFang SC;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-decoration: auto;
        }

        .insLoge {
            font-size: 48px;
        }
    }
}

section {
    .isLink {
        color: #2a73f7;
    }

    .col-lg-6 {
        margin: 60px 0 80px 0;

        .h2ss {
            font-size: 64px;
            color: #333;
            font-weight: 500;
            text-align: center;
        }
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    p {
        text-indent: 25px;
    }

    .titles {
        color: #333;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .isContent {
        color: #404040;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: PingFang SC;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
}
</style>